import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { Image, Size, Style, StyleType } from '@/types/entities'
import type { EnumImageGenerationStatus } from '../../../../../../../lib/database/lib/generated/enums'

interface GeneratorSettingsState {
  isLoaded: boolean
  isEnhancingPrompt: boolean
  generatingStatus: keyof typeof EnumImageGenerationStatus | null
  isPortrait: boolean
  prompt: string | null
  seed: string | null
  showImageGuide: boolean
  size: Size | null
  style: Style | null
  styleType: StyleType | null
  lastGeneratedImage: Image | null
  generationStartedAt: string | null
  gpuProviderId: string | null
}

const initialState = (): GeneratorSettingsState => {
  return {
    isLoaded: false,
    isEnhancingPrompt: false,
    generatingStatus: null,
    isPortrait: false,
    prompt: null,
    seed: null,
    showImageGuide: false,
    size: null,
    style: null,
    styleType: null,
    lastGeneratedImage: null,
    generationStartedAt: null,
    gpuProviderId: null,
  }
}

const generatorSettingsSlice = createSlice({
  name: 'generatorSettings',
  initialState,
  reducers: {
    setGeneratorSettingsIsLoaded(state, action: PayloadAction<boolean>) {
      state.isLoaded = action.payload
    },
    setGeneratorSettingsIsEnhancingPrompt(state, action: PayloadAction<boolean>) {
      state.isEnhancingPrompt = action.payload
    },
    setGeneratorSettingsGeneratingStatus(state, action: PayloadAction<keyof typeof EnumImageGenerationStatus | null>) {
      state.generatingStatus = action.payload

      if (action.payload !== null) {
        localStorage.setItem('generatorSettingsGeneratingStatus', action.payload)
      } else {
        localStorage.removeItem('generatorSettingsGeneratingStatus')
      }
    },
    setGeneratorSettingsIsPortrait(state, action: PayloadAction<boolean>) {
      state.isPortrait = action.payload
      localStorage.setItem('generatorSettingsIsPortrait', action.payload.toString())
    },
    setGeneratorSettingsPrompt(state, action: PayloadAction<string | null>) {
      state.prompt = action.payload

      if (action.payload !== null) {
        localStorage.setItem('generatorSettingsPrompt', action.payload)
      } else {
        localStorage.removeItem('generatorSettingsPrompt')
      }
    },
    setGeneratorSettingsSeed(state, action: PayloadAction<string | null>) {
      state.seed = action.payload

      if (action.payload !== null) {
        localStorage.setItem('generatorSettingsSeed', action.payload.toString())
      } else {
        localStorage.removeItem('generatorSettingsSeed')
      }
    },
    setGeneratorSettingsShowImageGuide(state, action: PayloadAction<boolean>) {
      state.showImageGuide = action.payload

      if (action.payload !== null) {
        localStorage.setItem('generatorSettingsShowImageGuide', action.payload.toString())
      } else {
        localStorage.removeItem('generatorSettingsShowImageGuide')
      }
    },
    setGeneratorSettingsSize(state, action: PayloadAction<Size | null>) {
      state.size = action.payload

      if (action.payload !== null) {
        localStorage.setItem('generatorSettingsSize', JSON.stringify(action.payload))
      } else {
        localStorage.removeItem('generatorSettingsSize')
      }
    },
    setGeneratorSettingsStyle(state, action: PayloadAction<Style | null>) {
      state.style = action.payload

      if (action.payload !== null) {
        localStorage.setItem('generatorSettingsStyle', JSON.stringify(action.payload))
      } else {
        localStorage.removeItem('generatorSettingsStyle')
      }
    },
    setGeneratorSettingsStyleType(state, action: PayloadAction<StyleType | null>) {
      state.styleType = action.payload

      if (action.payload !== null) {
        localStorage.setItem('generatorSettingsStyleType', JSON.stringify(action.payload))
      } else {
        localStorage.removeItem('generatorSettingsStyleType')
      }
    },
    setGeneratorSettingsLastGeneratedImage(state, action: PayloadAction<Image | null>) {
      state.lastGeneratedImage = action.payload

      if (action.payload !== null) {
        localStorage.setItem('generatorSettingsLastGeneratedImage', JSON.stringify(action.payload))
      } else {
        localStorage.removeItem('generatorSettingsLastGeneratedImage')
      }
    },
    setGeneratorSettingsGenerationStartedAt(state, action: PayloadAction<string | null>) {
      state.generationStartedAt = action.payload

      if (action.payload !== null) {
        localStorage.setItem('generatorSettingsGenerationStartedAt', action.payload)
      } else {
        localStorage.removeItem('generatorSettingsGenerationStartedAt')
      }
    },
    setGeneratorSettingsGpuProviderId(state, action: PayloadAction<string | null>) {
      state.gpuProviderId = action.payload

      if (action.payload !== null) {
        localStorage.setItem('generatorSettingsGpuProviderId', action.payload)
      } else {
        localStorage.removeItem('generatorSettingsGpuProviderId')
      }
    },
  },
  selectors: {
    getGeneratorSettingsIsLoaded: (sliceState) => sliceState.isLoaded,
    getGeneratorSettingsIsEnhancingPrompt: (sliceState) => sliceState.isEnhancingPrompt,
    getGeneratorSettingsGeneratingStatus: (sliceState) => sliceState.generatingStatus,
    getGeneratorSettingsIsPortrait: (sliceState) => sliceState.isPortrait,
    getGeneratorSettingsPrompt: (sliceState) => sliceState.prompt,
    getGeneratorSettingsSeed: (sliceState) => sliceState.seed,
    getGeneratorSettingsSize: (sliceState) => sliceState.size,
    getGeneratorSettingsShowImageGuide: (sliceState) => sliceState.showImageGuide,
    getGeneratorSettingsStyle: (sliceState) => sliceState.style,
    getGeneratorSettingsStyleType: (sliceState) => sliceState.styleType,
    getGeneratorSettingsLastGeneratedImage: (sliceState) => sliceState.lastGeneratedImage,
    getGeneratorSettingsGenerationStartedAt: (sliceState) => sliceState.generationStartedAt,
    getGeneratorSettingsGpuProviderId: (sliceState) => sliceState.gpuProviderId,
  },
})

export const {
  setGeneratorSettingsIsLoaded,
  setGeneratorSettingsIsEnhancingPrompt,
  setGeneratorSettingsGeneratingStatus,
  setGeneratorSettingsIsPortrait,
  setGeneratorSettingsPrompt,
  setGeneratorSettingsSeed,
  setGeneratorSettingsShowImageGuide,
  setGeneratorSettingsSize,
  setGeneratorSettingsStyle,
  setGeneratorSettingsStyleType,
  setGeneratorSettingsLastGeneratedImage,
  setGeneratorSettingsGenerationStartedAt,
  setGeneratorSettingsGpuProviderId,
} = generatorSettingsSlice.actions
export const {
  getGeneratorSettingsIsLoaded,
  getGeneratorSettingsIsEnhancingPrompt,
  getGeneratorSettingsGeneratingStatus,
  getGeneratorSettingsIsPortrait,
  getGeneratorSettingsPrompt,
  getGeneratorSettingsSeed,
  getGeneratorSettingsSize,
  getGeneratorSettingsShowImageGuide,
  getGeneratorSettingsStyle,
  getGeneratorSettingsStyleType,
  getGeneratorSettingsLastGeneratedImage,
  getGeneratorSettingsGenerationStartedAt,
  getGeneratorSettingsGpuProviderId,
} = generatorSettingsSlice.selectors
export default generatorSettingsSlice.reducer
