import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { Size, Style, StyleType } from '@/types/entities'

interface HistoryFiltersState {
  page: number | null
  perPage: number | null
  searchTerm: string | null
  size: Size | null
  style: Style | null
  styleType: StyleType | null
  aspectRatio: string | null
}

const initialState = (): HistoryFiltersState => {
  return {
    page: null,
    perPage: null,
    searchTerm: null,
    size: null,
    style: null,
    styleType: null,
    aspectRatio: null,
  }
}

const historyFiltersSlice = createSlice({
  name: 'historyFilters',
  initialState,
  reducers: {
    setHistoryFiltersPage(state, action: PayloadAction<number | null>) {
      state.page = action.payload

      if (action.payload !== null) {
        localStorage.setItem('historyFiltersPage', action.payload.toString())
      } else {
        localStorage.removeItem('historyFiltersPage')
      }
    },
    setHistoryFiltersPerPage(state, action: PayloadAction<number | null>) {
      state.perPage = action.payload

      if (action.payload !== null) {
        localStorage.setItem('historyFiltersPerPage', action.payload.toString())
      } else {
        localStorage.removeItem('historyFiltersPerPage')
      }
    },
    setHistoryFiltersSearchTerm(state, action: PayloadAction<string | null>) {
      state.searchTerm = action.payload

      if (action.payload !== null) {
        localStorage.setItem('historyFiltersSearchTerm', action.payload.toString())
      } else {
        localStorage.removeItem('historyFiltersSearchTerm')
      }
    },
    setHistoryFiltersSize(state, action: PayloadAction<Size | null>) {
      state.size = action.payload

      if (action.payload !== null) {
        localStorage.setItem('historyFiltersSize', JSON.stringify(action.payload))
      } else {
        localStorage.removeItem('historyFiltersSize')
      }
    },
    setHistoryFiltersStyle(state, action: PayloadAction<Style | null>) {
      state.style = action.payload

      if (action.payload !== null) {
        localStorage.setItem('historyFiltersStyle', JSON.stringify(action.payload))
      } else {
        localStorage.removeItem('historyFiltersStyle')
      }
    },
    setHistoryFiltersStyleType(state, action: PayloadAction<StyleType | null>) {
      state.styleType = action.payload

      if (action.payload !== null) {
        localStorage.setItem('historyFiltersStyleType', JSON.stringify(action.payload))
      } else {
        localStorage.removeItem('historyFiltersStyleType')
      }
    },
    setHistoryFiltersAspectRatio(state, action: PayloadAction<string | null>) {
      state.aspectRatio = action.payload

      if (action.payload !== null) {
        localStorage.setItem('historyFiltersAspectRatio', action.payload.toString())
      } else {
        localStorage.removeItem('historyFiltersAspectRatio')
      }
    },
  },
  selectors: {
    getHistoryFiltersPage: (sliceState) => sliceState.page,
    getHistoryFiltersPerPage: (sliceState) => sliceState.perPage,
    getHistoryFiltersSearchTerm: (sliceState) => sliceState.searchTerm,
    getHistoryFiltersSize: (sliceState) => sliceState.size,
    getHistoryFiltersStyle: (sliceState) => sliceState.style,
    getHistoryFiltersStyleType: (sliceState) => sliceState.styleType,
    getHistoryFiltersAspectRatio: (sliceState) => sliceState.aspectRatio,
  },
})

export const {
  setHistoryFiltersPage,
  setHistoryFiltersPerPage,
  setHistoryFiltersSearchTerm,
  setHistoryFiltersSize,
  setHistoryFiltersStyle,
  setHistoryFiltersStyleType,
  setHistoryFiltersAspectRatio,
} = historyFiltersSlice.actions
export const {
  getHistoryFiltersPage,
  getHistoryFiltersPerPage,
  getHistoryFiltersSearchTerm,
  getHistoryFiltersSize,
  getHistoryFiltersStyle,
  getHistoryFiltersStyleType,
  getHistoryFiltersAspectRatio,
} = historyFiltersSlice.selectors
export default historyFiltersSlice.reducer
