import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { StyleType } from '@/types/entities'

interface StyleTypesState {
  value: StyleType[] | null
}

const initialState = (): StyleTypesState => {
  return {
    value: null,
  }
}

const styleTypesSlice = createSlice({
  name: 'styleTypes',
  initialState,
  reducers: {
    setStyleTypes(state, action: PayloadAction<StyleType[] | null>) {
      state.value = action.payload
    },
  },
  selectors: {
    getStyleTypes: (sliceState) => sliceState.value,
  },
})

export const { setStyleTypes } = styleTypesSlice.actions
export const { getStyleTypes } = styleTypesSlice.selectors
export default styleTypesSlice.reducer
