import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { Size, Style, StyleType } from '@/types/entities'

interface BrowseFiltersState {
  page: number | null
  perPage: number | null
  searchTerm: string | null
  size: Size | null
  style: Style | null
  styleType: StyleType | null
  aspectRatio: string | null
}

const initialState = (): BrowseFiltersState => {
  return {
    page: null,
    perPage: null,
    searchTerm: null,
    size: null,
    style: null,
    styleType: null,
    aspectRatio: null,
  }
}

const browseFiltersSlice = createSlice({
  name: 'browseFilters',
  initialState,
  reducers: {
    setBrowseFiltersPage(state, action: PayloadAction<number | null>) {
      state.page = action.payload

      if (action.payload !== null) {
        localStorage.setItem('browseFiltersPage', action.payload.toString())
      } else {
        localStorage.removeItem('browseFiltersPage')
      }
    },
    setBrowseFiltersPerPage(state, action: PayloadAction<number | null>) {
      state.perPage = action.payload

      if (action.payload !== null) {
        localStorage.setItem('browseFiltersPerPage', action.payload.toString())
      } else {
        localStorage.removeItem('browseFiltersPerPage')
      }
    },
    setBrowseFiltersSearchTerm(state, action: PayloadAction<string | null>) {
      state.searchTerm = action.payload

      if (action.payload !== null) {
        localStorage.setItem('browseFiltersSearchTerm', action.payload.toString())
      } else {
        localStorage.removeItem('browseFiltersSearchTerm')
      }
    },
    setBrowseFiltersSize(state, action: PayloadAction<Size | null>) {
      state.size = action.payload

      if (action.payload !== null) {
        localStorage.setItem('browseFiltersSize', JSON.stringify(action.payload))
      } else {
        localStorage.removeItem('browseFiltersSize')
      }
    },
    setBrowseFiltersStyle(state, action: PayloadAction<Style | null>) {
      state.style = action.payload

      if (action.payload !== null) {
        localStorage.setItem('browseFiltersStyle', JSON.stringify(action.payload))
      } else {
        localStorage.removeItem('browseFiltersStyle')
      }
    },
    setBrowseFiltersStyleType(state, action: PayloadAction<StyleType | null>) {
      state.styleType = action.payload

      if (action.payload !== null) {
        localStorage.setItem('browseFiltersStyleType', JSON.stringify(action.payload))
      } else {
        localStorage.removeItem('browseFiltersStyleType')
      }
    },
    setBrowseFiltersAspectRatio(state, action: PayloadAction<string | null>) {
      state.aspectRatio = action.payload

      if (action.payload !== null) {
        localStorage.setItem('browseFiltersAspectRatio', action.payload.toString())
      } else {
        localStorage.removeItem('browseFiltersAspectRatio')
      }
    },
  },
  selectors: {
    getBrowseFiltersPage: (sliceState) => sliceState.page,
    getBrowseFiltersPerPage: (sliceState) => sliceState.perPage,
    getBrowseFiltersSearchTerm: (sliceState) => sliceState.searchTerm,
    getBrowseFiltersSize: (sliceState) => sliceState.size,
    getBrowseFiltersStyle: (sliceState) => sliceState.style,
    getBrowseFiltersStyleType: (sliceState) => sliceState.styleType,
    getBrowseFiltersAspectRatio: (sliceState) => sliceState.aspectRatio,
  },
})

export const {
  setBrowseFiltersPage,
  setBrowseFiltersPerPage,
  setBrowseFiltersSearchTerm,
  setBrowseFiltersSize,
  setBrowseFiltersStyle,
  setBrowseFiltersStyleType,
  setBrowseFiltersAspectRatio,
} = browseFiltersSlice.actions
export const {
  getBrowseFiltersPage,
  getBrowseFiltersPerPage,
  getBrowseFiltersSearchTerm,
  getBrowseFiltersSize,
  getBrowseFiltersStyle,
  getBrowseFiltersStyleType,
  getBrowseFiltersAspectRatio,
} = browseFiltersSlice.selectors
export default browseFiltersSlice.reducer
