import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { OrientationType } from '@/types/entities'

interface OrientationTypesState {
  value: OrientationType[] | null
}

const initialState = (): OrientationTypesState => {
  return {
    value: null,
  }
}

const orientationTypesSlice = createSlice({
  name: 'orientationTypes',
  initialState,
  reducers: {
    setOrientationTypes(state, action: PayloadAction<OrientationType[] | null>) {
      state.value = action.payload
    },
  },
  selectors: {
    getOrientationTypes: (sliceState) => sliceState.value,
  },
})

export const { setOrientationTypes } = orientationTypesSlice.actions
export const { getOrientationTypes } = orientationTypesSlice.selectors
export default orientationTypesSlice.reducer
