import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { SizeType } from '@/types/entities'

interface SizeTypesState {
  value: SizeType[] | null
}

const initialState = (): SizeTypesState => {
  return {
    value: null,
  }
}

const sizeTypesSlice = createSlice({
  name: 'sizeTypes',
  initialState,
  reducers: {
    setSizeTypes(state, action: PayloadAction<SizeType[] | null>) {
      state.value = action.payload
    },
  },
  selectors: {
    getSizeTypes: (sliceState) => sliceState.value,
  },
})

export const { setSizeTypes } = sizeTypesSlice.actions
export const { getSizeTypes } = sizeTypesSlice.selectors
export default sizeTypesSlice.reducer
