import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { Size } from '@/types/entities'

interface SizesState {
  value: Size[] | null
}

const initialState = (): SizesState => {
  return {
    value: null,
  }
}

const sizesSlice = createSlice({
  name: 'sizes',
  initialState,
  reducers: {
    setSizes(state, action: PayloadAction<Size[] | null>) {
      state.value = action.payload
    },
  },
  selectors: {
    getSizes: (sliceState) => sliceState.value,
  },
})

export const { setSizes } = sizesSlice.actions
export const { getSizes } = sizesSlice.selectors
export default sizesSlice.reducer
