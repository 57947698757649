import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { ImageGuideType } from '@/types/entities'

interface ImageGuideTypesState {
  value: ImageGuideType[] | null
}

const initialState = (): ImageGuideTypesState => {
  return {
    value: null,
  }
}

const imageGuideTypesSlice = createSlice({
  name: 'imageGuideTypes',
  initialState,
  reducers: {
    setImageGuideTypes(state, action: PayloadAction<ImageGuideType[] | null>) {
      state.value = action.payload
    },
  },
  selectors: {
    getImageGuideTypes: (sliceState) => sliceState.value,
  },
})

export const { setImageGuideTypes } = imageGuideTypesSlice.actions
export const { getImageGuideTypes } = imageGuideTypesSlice.selectors
export default imageGuideTypesSlice.reducer
