import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { DateTime } from 'luxon'
import type { Coupon, Price } from '@/types/entities'

interface CouponsState {
  value: Coupon[] | null
}

const initialState = (): CouponsState => {
  return {
    value: null,
  }
}

const couponsSlice = createSlice({
  name: 'coupons',
  initialState,
  reducers: {
    setCoupons(state, action: PayloadAction<Coupon[] | null>) {
      state.value = action.payload
    },
  },
  selectors: {
    getCoupons: (sliceState) => sliceState.value,
    getCouponByPromoCode: (sliceState) => (promoCode: string) =>
      sliceState.value?.find((coupon) => coupon.promotion_code === promoCode) ?? null,
    getFirstActivePublicCoupon: (sliceState) => {
      const coupons = sliceState.value

      if (!coupons || coupons.length === 0) {
        return null
      }

      return (
        coupons.find(
          (coupon) => coupon.is_active && coupon.is_public && (!coupon.expires_at || DateTime.now() < DateTime.fromISO(coupon.expires_at)),
        ) ?? null
      )
    },
    getBestDiscountCoupon: (sliceState) => (price?: Price | null) => {
      const coupons = sliceState.value

      if (!price || !coupons || coupons.length === 0) {
        return null
      }

      if (coupons.length === 1) {
        return coupons[0]
      }

      let bestCoupon = coupons.shift() as Coupon

      coupons.forEach((coupon) => {
        const couponDiscount = coupon.percent_off ? (+coupon.percent_off / 100) * price.price : (coupon.amount_off as number)
        const bestCouponDiscount = bestCoupon?.percent_off
          ? (+bestCoupon.percent_off / 100) * price.price
          : (bestCoupon.amount_off as number)

        if (couponDiscount > bestCouponDiscount) {
          bestCoupon = coupon
        }
      })

      return bestCoupon
    },
  },
})

export const { setCoupons } = couponsSlice.actions
export const { getCoupons, getCouponByPromoCode, getFirstActivePublicCoupon, getBestDiscountCoupon } = couponsSlice.selectors
export default couponsSlice.reducer
